.form-wizard {

	.nav-pills {
		position: relative;

		>li {

			>a {
				z-index: 1;
				opacity: 1;
				position: relative;
				display: block;

				.step {
					width: 36px;
					line-height: 28px;
	    			height: 36px;
				    border-radius: 50%;
				    border: 4px solid $border-color;
				    background: $default-white;
				    color: $default-text-color;
				    display: inline-block;
			    }

			    .title {
			    	position: absolute;
				    width: 100%;
				    left: 0;
				    bottom: -20px;
			    }

			    &.active {
					background-color: transparent;
					border-color: transparent;
					color: $default-text-color;

					.step {
						background-color: $default-info;
						border-color: $default-info;
						color: $default-white;
					}
				}
			}
		}
	}

	.progress {
		position: absolute;
	    display: inline-block;
	    top: 24px;
	    left: 0;
	    right: 0;
	    margin: auto;
	    height: 5px;
	    border-radius: 0;
	}

	.tab-content {
		margin-top: 90px;
		margin-bottom: 30px;
	}
}
.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
	line-height: 1.8;
	border-color: $border-color;
}

.table-sm>tbody>tr>td,
.table-sm>tbody>tr>th,
.table-sm>tfoot>tr>td,
.table-sm>tfoot>tr>th,
.table-sm>thead>tr>td,
.table-sm>thead>tr>th {
	padding: 5px;
}

.table-lg>tbody>tr>td,
.table-lg>tbody>tr>th,
.table-lg>tfoot>tr>td,
.table-lg>tfoot>tr>th,
.table-lg>thead>tr>td,
.table-lg>thead>tr>th {
	padding: 15px;
}

.table-xl>tbody>tr>td,
.table-xl>tbody>tr>th,
.table-xl>tfoot>tr>td,
.table-xl>tfoot>tr>th,
.table-xl>thead>tr>td,
.table-xl>thead>tr>th {
	padding: 20px;
}

.table-striped{
	> tbody {

		> tr {

			&:nth-of-type(odd) {
				background-color: lighten($default-gray, 1.5%);
			}
		}
	}
}

.table-hover  {
	> tbody{

		> tr {

			&:hover {
				background-color: $default-gray;
			}
		}
	}
}

.table-bordered {
    border: 1px solid $border-color;
}

.table {
	> thead {
		>tr {
			> th {
				color: $default-dark;
				border-bottom: 1px solid $border-color;
			}

			> td {

			}
		}
	}
}

.table-overflow {
	@include screen-tablet {
		width: 100%;
		overflow-x: auto;
	}
}


#addresses i{
  display: none;
}
#addresses tr:hover i{
  display: block;
}

.gallery-item {
	display: block;
	position: relative;
	cursor: pointer;

	.overlay {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		opacity: 0;
		visibility: hidden;
		@include background-opacity(#000, 0.7);
		@include transition(all 0.4s ease-in-out);

		.overlay-content {
			position: absolute;
			padding: 20px 15px;
			width: 100%;
			left: 0px;
			bottom: 0px;

			.caption-title {
				color: $default-white;
				margin: 0px;
			}

			.caption-date {
				color: darken($default-white, 20%);
			}
		}
	}

	&:hover {
		.overlay {
			opacity: 1;
			visibility: visible;
		}
	}
}

.pswp__caption__center {
	text-align: center;
}
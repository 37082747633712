/*! nouislider - 10.1.0 - 2017-07-28 17:11:18 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
 .noUi-target,
 .noUi-target * {
   -webkit-touch-callout: none;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
   -webkit-user-select: none;
   -ms-touch-action: none;
   touch-action: none;
   -ms-user-select: none;
   -moz-user-select: none;
   user-select: none;
   -moz-box-sizing: border-box;
   box-sizing: border-box;
 }
 .noUi-target {
   position: relative;
   direction: ltr;
 }
 .noUi-base {
   width: 100%;
   height: 100%;
   position: relative;
   z-index: 1;
   /* Fix 401 */
 }
 .noUi-connect {
   position: absolute;
   right: 0;
   top: 0;
   left: 0;
   bottom: 0;
 }
 .noUi-origin {
   position: absolute;
   height: 0;
   width: 0;
 }
 .noUi-handle {
   position: relative;
   z-index: 1;
 }
 .noUi-state-tap .noUi-connect,
 .noUi-state-tap .noUi-origin {
   -webkit-transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
   transition: top 0.3s, right 0.3s, bottom 0.3s, left 0.3s;
 }
 .noUi-state-drag * {
   cursor: inherit !important;
 }
 /* Painting and performance;
  * Browsers can paint handles in their own layer.
  */
 .noUi-base,
 .noUi-handle {
   -webkit-transform: translate3d(0, 0, 0);
   transform: translate3d(0, 0, 0);
 }
 /* Slider size and handle placement;
  */
 .noUi-horizontal {
   height: 18px;
 }
 .noUi-horizontal .noUi-handle {
   width: 34px;
   height: 28px;
   left: -17px;
   top: -6px;
 }
 .noUi-vertical {
   width: 18px;
 }
 .noUi-vertical .noUi-handle {
   width: 28px;
   height: 34px;
   left: -6px;
   top: -17px;
 }
 /* Styling;
  */
 .noUi-target {
   background: #FAFAFA;
   border-radius: 4px;
   border: 1px solid #D3D3D3;
   box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB;
 }
 .noUi-connect {
   background: #3FB8AF;
   border-radius: 4px;
   box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45);
   -webkit-transition: background 450ms;
   transition: background 450ms;
 }
 /* Handles and cursors;
  */
 .noUi-draggable {
   cursor: ew-resize;
 }
 .noUi-vertical .noUi-draggable {
   cursor: ns-resize;
 }
 .noUi-handle {
   border: 1px solid #D9D9D9;
   border-radius: 3px;
   background: #FFF;
   cursor: default;
   box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB;
 }
 .noUi-active {
   box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB;
 }
 /* Handle stripes;
  */
 .noUi-handle:before,
 .noUi-handle:after {
   content: "";
   display: block;
   position: absolute;
   height: 14px;
   width: 1px;
   background: #E8E7E6;
   left: 14px;
   top: 6px;
 }
 .noUi-handle:after {
   left: 17px;
 }
 .noUi-vertical .noUi-handle:before,
 .noUi-vertical .noUi-handle:after {
   width: 14px;
   height: 1px;
   left: 6px;
   top: 14px;
 }
 .noUi-vertical .noUi-handle:after {
   top: 17px;
 }
 /* Disabled state;
  */
 [disabled] .noUi-connect {
   background: #B8B8B8;
 }
 [disabled].noUi-target,
 [disabled].noUi-handle,
 [disabled] .noUi-handle {
   cursor: not-allowed;
 }
 /* Base;
  *
  */
 .noUi-pips,
 .noUi-pips * {
   -moz-box-sizing: border-box;
   box-sizing: border-box;
 }
 .noUi-pips {
   position: absolute;
   color: #999;
 }
 /* Values;
  *
  */
 .noUi-value {
   position: absolute;
   white-space: nowrap;
   text-align: center;
 }
 .noUi-value-sub {
   color: #ccc;
   font-size: 10px;
 }
 /* Markings;
  *
  */
 .noUi-marker {
   position: absolute;
   background: #CCC;
 }
 .noUi-marker-sub {
   background: #AAA;
 }
 .noUi-marker-large {
   background: #AAA;
 }
 /* Horizontal layout;
  *
  */
 .noUi-pips-horizontal {
   padding: 10px 0;
   height: 80px;
   top: 100%;
   left: 0;
   width: 100%;
 }
 .noUi-value-horizontal {
   -webkit-transform: translate3d(-50%, 50%, 0);
   transform: translate3d(-50%, 50%, 0);
 }
 .noUi-marker-horizontal.noUi-marker {
   margin-left: -1px;
   width: 2px;
   height: 5px;
 }
 .noUi-marker-horizontal.noUi-marker-sub {
   height: 10px;
 }
 .noUi-marker-horizontal.noUi-marker-large {
   height: 15px;
 }
 /* Vertical layout;
  *
  */
 .noUi-pips-vertical {
   padding: 0 10px;
   height: 100%;
   top: 0;
   left: 100%;
 }
 .noUi-value-vertical {
   -webkit-transform: translate3d(0, 50%, 0);
   transform: translate3d(0, 50%, 0);
   padding-left: 25px;
 }
 .noUi-marker-vertical.noUi-marker {
   width: 5px;
   height: 2px;
   margin-top: -1px;
 }
 .noUi-marker-vertical.noUi-marker-sub {
   width: 10px;
 }
 .noUi-marker-vertical.noUi-marker-large {
   width: 15px;
 }
 .noUi-tooltip {
   display: block;
   position: absolute;
   border: 1px solid #D9D9D9;
   border-radius: 3px;
   background: #fff;
   color: #000;
   padding: 5px;
   text-align: center;
   white-space: nowrap;
 }
 .noUi-horizontal .noUi-tooltip {
   -webkit-transform: translate(-50%, 0);
   transform: translate(-50%, 0);
   left: 50%;
   bottom: 120%;
 }
 .noUi-vertical .noUi-tooltip {
   -webkit-transform: translate(0, -50%);
   transform: translate(0, -50%);
   top: 50%;
   right: 120%;
 }
 
.noUi-handle {
	background-color: $default-white;
	border-radius: 50%;
	box-shadow: none;
	cursor: pointer;
	@include transition(all 0.2s ease); 

	&:active {
	    	@include transform(scale(1.4));
	}

    &:before,
    &:after {
    	display: none;	
    }
}

.noUi-horizontal {
	height: 6px;

	.noUi-handle {
	    left: -10px;
	    top: -7px;
	    width: 18px;
		height: 18px;
		border: 1px solid darken($border-color, 5%);
	}
}	


.noUi-vertical {
	height: 100%;
	width: 6px;
	display: inline-block;

	.noUi-handle {
		width: 18px;
		height: 18px;
		border: 1px solid darken($border-color, 5%);
	}

	.noUi-origin {
	    border-radius: 0px;
	    bottom: 0px;
	}
}

.noUi-background {
    background: $default-gray;
    box-shadow: none;
}

.noUi-origin {
    border-radius: 0px;
    bottom: 5px;
}

.noUi-target {
	border: 1px solid $border-color;
	box-shadow: none;

	&.noUi-connect {
		box-shadow: none;
	}
}

.noUi-connect {
	background-color: $default-text-color;

	&.slider-primary {
		background-color: $default-primary;	
	}

	&.slider-success {
		background-color: $default-success;	
	}

	&.slider-info {
		background-color: $default-info;	
	}

	&.slider-warning {
		background-color: $default-warning;	
	}

	&.slider-danger {
		background-color: $default-danger;	
	}
}

.slider-primary {

	.noUi-connect {
		background-color: $default-primary;	
	}
}

.slider-success {

	.noUi-connect {
		background-color: $default-success;	
	}
}

.slider-info {

	.noUi-connect {
		background-color: $default-info;	
	}
}

.slider-warning {

	.noUi-connect {
		background-color: $default-warning;	
	}
}

.slider-danger {

	.noUi-connect {
		background-color: $default-danger;	
	}
}

.noUi-marker-large,
.noUi-marker-sub  {
	background: $border-color;
 }

.noUi-pips {
	color: $default-text-color;	
}

.noUi-value-sub {
	color: $default-text-color;	
	margin-top: 5px;
	font-size: 11px
}

.noUi-value {
	margin-top: 5px;
    font-size: 12px;
}
.noUi-marker-horizontal.noUi-marker-large {
	height: 10px;
}
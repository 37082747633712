$color-black: #000000;
$color-gray: #868686;
$color-white: #ffff;
$color-blue: #1565d8; //noknok theme color
$color-gray-light: #a3a3a3;
$color-tag-line: #1e64ff; //noknok tag-line color

.authentication {
  .sign-in {
    .img-caption {
      position: absolute;
      bottom: 0px;
      left: 0px;
      padding: 30px;
      max-width: 600px;

      .caption-title {
        color: $default-white;
        font-size: 35px;
        font-weight: 300;
      }

      .caption-text {
        color: darken($default-white, 10%);
      }
    }

    .login-footer {
      position: absolute;
      bottom: 0px;
      padding: 15px 30px;
      border-top: 1px solid $border-color;
      width: 100%;
      background: white;
    }
  }
}

.login-page {
  background-color: $color-white !important;

  #loginImageSize {
    height: 100vh;
  }

  .login {
    position: relative;
    font-family: Inter;
    padding: 10px;
    margin: auto;

    .login-body {
      .login-heading {
        font-weight: bold;
        font-size: 30px;
        line-height: 36px;
        color: $color-black;
      }

      .login-info {
        color: $color-gray;
      }

      .btn-info {
        background-color: $color-blue;
        margin-right: 0px !important;
        border-radius: 2px;
      }

      #forgot-password {
        color: $color-gray;
        display: flex;
        justify-content: center;
        margin-top: 25px;
      }

      .eye-icon {
        position: absolute;
        color: $color-gray-light;
        cursor: pointer;
        right: 30px;
        top: 26px;
      }

      .tag-line {
        color: $color-tag-line;
        text-align: center !important;
      }

      .margin-top-7 {
        margin-top: 7em !important;
      }

      #login-btn {
        margin-top: 2.5em !important;
      }

      .floating-label {
        position: absolute;
        top: 12px;
        padding: 8px 0 0 16px;
        transition: all 200ms;
        color: #212529;
      }

      .form-control {
        height: 65px;

        &:focus + .floating-label,
        &:valid + .floating-label,
        &:-webkit-autofill + .floating-label {
          font-size: 75%;
          transform: scale(1.1) translateY(-1.9rem) translateX(0rem);
          color: $color-gray-light;
          top: 41px;
          opacity: 0.65;
          font-family: Inter;
        }
        &:not(:placeholder-shown) {
          padding: 13px 0px 0px 13px;
        }
      }
    }
  }

  .parent {
    position: relative;
    top: 0;
    left: 0;

    .background-image {
      position: relative;
      top: 0;
      left: 0;
    }

    .noknok-left-logo {
      position: absolute;
      height: 12vh;
      top: 100px;
      left: 325px;
    }

    .city {
      position: absolute;
      height: 15vh;
      top: 465px;
      left: 153px;
    }

    .cloud-air {
      position: absolute;
      height: 28vh;
      top: 256px;
      left: 150px;
    }

    .bike {
      position: absolute;
      height: 45vh;
      top: 275px;
      left: 220px;
    }

    .noknok-tag-line {
      position: absolute;
      height: 3vh;
      top: 630px;
      left: 185px;

      h1 {
        text-align: center !important;
        color: $color-tag-line;
      }
    }

    .container {
      position: absolute;
      text-align: center;
      color: $color-white;

      .bubbles {
        position: absolute;
        bottom: 395px;
        left: 390px;
      }

      .centered {
        position: absolute;
        width: 25%;
        bottom: 510px;
        left: 555px;
        font-size: 18px;
        font-family: 'Quicksand', sans-serif;
        line-height: 1.2;

        .expression {
          line-height: 1.7;
          font-family: 'Fuzzy Bubbles', cursive;
          font-size: 30px;
        }
      }
    }

    @media (min-width: 1600px) {
      .noknok-tag-line {
        position: absolute;
        top: 750px;
        left: 210px;
      }

      .noknok-left-logo {
        position: absolute;
        top: 100px;
        left: 360px;
      }

      .cloud-air {
        position: absolute;
        top: 230px;
        left: 125px;
      }

      .city {
        position: absolute;
        top: 520px;
        left: 135px;
      }

      .container {
        position: absolute;
        text-align: center;
        color: $color-white;

        .bubbles {
          position: absolute;
          bottom: 595px;
          left: 435px;
        }

        .centered {
          position: absolute;
          width: 25%;
          bottom: 713px;
          left: 575px;
          font-weight: bold;
          font-size: 18px;
          font-family: 'Quicksand', sans-serif;
          line-height: 1.2;

          .expression {
            line-height: 1.7;
            font-family: 'Fuzzy Bubbles', cursive;
            font-size: 30px;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .hide-image {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .responsive-column {
    flex: 0 0 8.3333333333%;
    max-width: 4%;
  }
}

@import '../variables'; 

[_nghost-c1] {
    border: 1px solid $border-color !important;
    padding: 25px 20px !important;
}

.bg-light {
    background-color: $default-white !important;
}

.ngb-dp-week {
    padding-bottom: 5px;
}

.ngb-dp-weekdays {
    border-bottom: 0px !important;
}

[_nghost-c2] {
    color: $default-text-color;
    width: 36px !important;
    height: 32px !important;
    border-radius: 0 !important;
}

.ngb-dp-day[_ngcontent-c4], .ngb-dp-weekday[_ngcontent-c4], .ngb-dp-week-number[_ngcontent-c4] {
    width: 36px !important;
    height: 32px !important;
}

.ngb-dp-weekday {
    color: $default-text-color !important;
    font-weight: bold;
    font-style: normal !important;
    font-size: 13px;
}

select[_ngcontent-c5] {
    width: calc(50% - 10px) !important;
    margin: 0px 5px !important;
}

.ngb-dp-arrow-btn {
    margin: 0px !important;
}

.ngb-dp-navigation-chevron:before {
    border-width: 1px 1px 0 0 !important;
}

.btn-link {
    color: $default-text-color;

    &:hover,
    &:focus {
        color: $default-dark;
        background-color: $default-gray;
    }
}

.ngb-dp-month-name {
    margin: 10px 0px;
    color: $default-dark;
}

.content-footer {
	padding: 0px 30px;

    .footer {
    	padding: 20px 0;
    	font-size: 90%;
    	border-top: 1px solid $border-color;

    	.go-right {
    		float: right;
    	}
    }
}
button,
input,
textarea {
	outline: none;
}

.form-group {
	margin-bottom: 25px;
}

.form-control {
	border: 1px solid $border-color;
	border-radius: 2px;
	box-shadow: none;
	height: 42px;
	font-size: 14px;
	color: $default-text-color;
	@include transition(all 0.2s ease-in);
	@include placeholder {
		color: lighten($default-text-color, 30%);
	}

	&:focus {
		outline: 0 none;
		box-shadow: none;
		border-color: lighten($default-info, 10%);
	}

	&.input-sm {
		height: 30px;
	}

	&.input-lg {
		height: 50px;
	}
  &.input-custom {
    height: 34px;
  }

	&.error {
		border-color: $default-danger;
		background-color: $inverse-danger;
	}

	&.valid {
		border-color: $default-success;
		background-color: $inverse-success;
		color: darken($default-success, 15%);
	}
}

.has-success {

	.form-control {
		box-shadow: none;
		border-color: $default-success;

		&:focus {
			border-color: $default-success;
			box-shadow: none;
		}
	}

	.checkbox,
	.checkbox-inline,
	.control-label,
	.help-block,
	.radio,
	.radio-inline,
	&.checkbox label
	&.checkbox-inline label,
	&.radio label,
	&.radio-inline label {
		color: $default-success;
	}
}

.has-warning {

	.form-control {
		box-shadow: none;
		border-color: $default-warning;

		&:focus {
			border-color: $default-warning;
			box-shadow: none;
		}
	}

	.checkbox,
	.checkbox-inline,
	.control-label,
	.help-block,
	.radio,
	.radio-inline,
	&.checkbox label
	&.checkbox-inline label,
	&.radio label,
	&.radio-inline label {
		color: $default-warning;
	}
}

.has-error {

	.form-control {
		box-shadow: none;
		border-color: $default-danger;

		&:focus {
			border-color: $default-danger;
			box-shadow: none;
		}
	}
  .ng-select.custom .ng-select-container {
    border-color: $default-danger;
    box-shadow: none;
  }
	.checkbox,
	.checkbox-inline,
	.control-label,
	.help-block,
	.radio,
	.radio-inline,
	&.checkbox label
	&.checkbox-inline label,
	&.radio label,
	&.radio-inline label {
		color: $default-danger;
	}
}

textarea {
	&.form-control {
		// min-height: 175px;
	}
}

.input-icon {
	position: relative;

	i {
		position: absolute;
		left: 10px;
   		top: 15px;
   		color: lighten($default-text-color, 30%);
	}

	.form-control {
		padding-left: 40px;
	}

}

.input-group-addon {
	background-color: transparent;
	border: 1px solid $border-color;
	color: lighten($default-text-color, 30%);

	.btn {
		margin: 0px;
		padding: 0px;
		background-color: transparent;
	}
}

.timepicker-input {
	.input-group-addon {
		cursor: pointer;
	}
}

.checkbox {
	padding: 7px 0;
    min-height: auto;

	input[type=checkbox] {
		margin: 0;
		display: none;
		width: 22px;

		+label {
			padding-left: 0;

			&:before {
				content: "";
				width: 20px;
				height: 20px;
				display: inline-block;
				border: 2px solid $border-color;
				border-radius: 3px;
				margin-right: 10px;
				font-size: 13px;
				font-family: 'themify';
				font-weight: bolder;
				line-height: 17px;
				vertical-align: bottom;
				text-align: center;
				background-color: $default-white;
				cursor: pointer;
			}
		}

		&:checked {

			+label {
				&:before {
					content: "\e64c";
					color: $default-white;
					border: 2px solid $default-info;
					background-color: $default-info;
				}
			}
		}
	}

	&.checkbox-primary {
		input[type=checkbox] {
			&:checked {

				+label {
					&:before {
						content: "\e64c";
						color: $default-primary;
					}
				}
			}
		}
	}

	&.checkbox-success {
		input[type=checkbox] {
			&:checked {

				+label {
					&:before {
						content: "\e64c";
						color: $default-success;
					}
				}
			}
		}
	}

	&.checkbox-warning {
		input[type=checkbox] {
			&:checked {

				+label {
					&:before {
						content: "\e64c";
						color: $default-warning;
					}
				}
			}
		}
	}

	&.checkbox-danger {
		input[type=checkbox] {
			&:checked {

				+label {
					&:before {
						content: "\e64c";
						color: $default-danger;
					}
				}
			}
		}
	}
  &.checkbox-line {
    input[type=checkbox] {
      &:checked {

        +label {
          &:before {
            content: "\e622";
          }
        }
      }
    }
  }
}

.radio {
	padding: 7px 0;
    min-height: auto;

	input[type=radio] {
		margin: 0;
		display: none;
		width: 22px;

		+label {
			padding-left: 0;

			&:before {
				content: "";
				width: 22px;
				height: 22px;
				display: inline-block;
				border: 2px solid $border-color;
				border-radius: 50%;
				margin-right: 10px;
				font-size: 14px;
				font-family: 'FontAwesome';
				font-weight: 400;
				line-height: 19px;
				vertical-align: bottom;
				text-align: center;
				background-color: $default-white;
				cursor: pointer;
			}
		}

		&:checked {

			+label {
				&:before {
					content: "\f111";
					color: $default-info;
				}
			}
		}
	}

	&.radio-primary {
		input[type=radio] {
			&:checked {

				+label {
					&:before {
						content: "\f111";
						color: $default-primary;
					}
				}
			}
		}
	}

	&.radio-success {
		input[type=radio] {
			&:checked {

				+label {
					&:before {
						content: "\f111";
						color: $default-success;
					}
				}
			}
		}
	}

	&.radio-warning {
		input[type=radio] {
			&:checked {

				+label {
					&:before {
						content: "\f111";
						color: $default-warning;
					}
				}
			}
		}
	}

	&.radio-danger {
		input[type=radio] {
			&:checked {

				+label {
					&:before {
						content: "\f111";
						color: $default-danger;
					}
				}
			}
		}
	}
}

.radio,
.checkbox {
	label{
		cursor: pointer;
	}
}

.checkbox-inline,
.radio-inline {
	margin-right: 15px;
	margin-top: 0px;
	display: inline-block;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
	 margin-top: 0px;
     margin-left: 0px;
}

.toggle-checkbox {

	input[type=checkbox] {
		opacity: 0;
		position: absolute;

		+label {
			position: relative;
		    display: inline-block;
		    @include transition(.4s ease);
		    height: 30px;
		    width: 50px;
		    border: 1px solid $border-color;
		    border-radius: 60px;
		    cursor: pointer;

		    &:before {
		    	content: "";
				position: absolute;
		        display: block;
				@include transition(0.2s cubic-bezier(0.24, 0, 0.5, 1));
				height: 30px;
				width: 50px;
				top: 0;
				left: 0;
				border-radius: 30px;
		    }

		    &:after {
		    	content: "";
				position: absolute;
				display: block;
				box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0px 0 rgba(0, 0, 0, 0.04), 0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05);
				@include transition(0.35s cubic-bezier(0.54, 1.6, 0.5, 1));
				background: darken($default-white,3%);
				height: 28px;
				width: 28px;
				top: 0px;
				left: 0px;
				border-radius: 60px;
		    }
		}

		&:checked {

			+label {

				&:before {
					background: $default-info;
					@include transition(width 0.2s cubic-bezier(0, 0, 0, 0.1));
				}

				&:after {
					left: 24px;
				}
			}
		}
	}


	&.toggle-sm {
		input[type=checkbox] {

			+label {
				height: 20px;
				width: 35px;

				&:before {
					height: 20px;
					width: 35px;
				}

				&:after {
					height: 19px;
					width: 19px;
				}
			}

			&:checked {

				+label {
					&:after {
						left: 16px;
					}
				}
			}
		}
	}

	&.toggle-primary {

		input[type=checkbox] {
			&:checked {

  				+label {

  					&:before {
  						background: $default-primary;
  					}
  				}
  			}
		}
	}

	&.toggle-success {

		input[type=checkbox] {
			&:checked {

  				+label {

  					&:before {
  						background: $default-success;
  					}
  				}
  			}
		}
	}

	&.toggle-warning {

		input[type=checkbox] {
			&:checked {

  				+label {

  					&:before {
  						background: $default-warning;
  					}
  				}
  			}
		}
	}

	&.toggle-danger {

		input[type=checkbox] {
			&:checked {

  				+label {

  					&:before {
  						background: $default-danger;
  					}
  				}
  			}
		}
	}
}


